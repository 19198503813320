
.session-item {
  font-size: 0.9rem;
  border-radius: 20px;
  position: relative !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;

  .card-footer {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    line-height: 1rem;

    .footer-text {
      font-size: 0.8rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .head {
    font-size: 0.8rem;
    padding-bottom: 5px;
    color: grey;
    width: 100%;

    .type {
      color: grey;
      width: 80%;
    }

   

    .time {
      //padding-top: 5px;
      line-height: 1rem;
      width: 80%;
    }
  }
   .favorite-button{
      position: absolute;
      top: 10px;
      width: 20%;
      right:20px;
    }


  .content {
    .title {
      font-weight: 500;
      line-height: 1.1rem;
      padding-bottom: 10px;
    }

    .chair {
      font-size: 0.8rem;
      color: grey;
      line-height: 1.0rem;
      span:not(:last-child)::after {
        content: ", ";
      }
    }

    .speaker {
      color: grey;
      font-size: 0.8rem;
      line-height: 1.0rem;
      span:not(:last-child)::after {
        content: ", ";
      }
    }

  }

  .code-wrapper {
    position: absolute;
    right: 0;
    height: 20px;
    font-size: 0.8rem;
    width: 55px;
    border-top-right-radius: 20px;

    .code {
      white-space: nowrap;
      display: block;
      width: 20px;
      height: inherit;
      padding-left: 10px;

    }
  }
}

@media(max-width: 767px) {
  .session-item {
    border-radius: 0;
    border: none;
    //box-shadow: none !important;
    box-shadow: 0 0.125rem 0.125rem rgb(0 0 0 / 5%) !important;

    .card-body {
      padding: 5px 20px;
    }

    .head {
      .time {
        padding-top: 0;
      }
    }

    .content {
      .title {
        font-weight: 400;
      }
    }

    .code-wrapper {
      position: absolute;
      left: 5px;
      height: inherit;
      width: 8px;
      border-radius: 5px;

      .code {
        writing-mode: tb-rl;
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
        white-space: nowrap;
        display: block;
        width: 20px;
        height: inherit;
        padding-top: 10px;
        padding-left: 0;
      }
    }
  }
}
