
.loader {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  ion-spinner {
    margin-right: 10px;
  }
}

.favorite-button {
  width: 100%;
  background: #ba2526;
  color: #7b7979;
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
}
